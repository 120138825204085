import React from 'react';

const VODS = () => {
  return (
    <div className="body-container">
      <h1>VODs</h1>
      <p>Pete needs to make a real VODS page.</p>
      <a href="https://www.youtube.com/channel/UCvgO-a0Fh-SXIZMUgYnE36w/videos">Click here for the RTM YouTube channel with past tournament videos.</a>
    </div>
  );
};

export default VODS;
