import React, {useState} from 'react';
import GameCard from '../components/game-card';
import Modal from '../components/modal/modal';
import '../components/modal/modal.scss';
import './events.scss';

const Monthly = () => {
    const makeStaticContentLink = (path) => `${process.env.PUBLIC_URL}/${path}`;
  const [showModal, toggleModal] = useState(false); 
  const [showSecondModal, toggleSecondModal] = useState(false);
  return (
    <div className="big-event-container">
            <img className="blast-layer" src={makeStaticContentLink('bigblastpng.png')}></img>

 {/* <img className="city-layer" src={makeStaticContentLink('minneapolis-skyline-png.png')}></img> */}
            {/* <img className="snow-gif" src={makeStaticContentLink('gif-png-snow-2.gif')}></img> */}
            <div className="big-event-content">
                <img className="big-ol-logo" src={process.env.PUBLIC_URL + '/monthly3logodraft.png'}></img>
                <div className="logo-sub-description">
                    <p>Saturday September 17th, 2022 @ GameZenter in Roseville</p>
                    <p className="tournament-time">11:00 AM - 10:00 PM | $15 Venue/Casuals | $10 Pot Per Bracket</p>
                    <p className="tournament-time">Spectators get in free</p>
                </div>

                <div className="register-button-wrapper">
                <a className="register-now" href="https://smash.gg/runthemonthly">
                    <p className="register-text">REGISTER HERE NOW!</p>
                </a>
                <br></br>
                <div className="big-event-info-block">
                    <p>It's the RunTheMix weekly, but mostly-Monthly!</p>
                    <p>Come enjoy a day of casuals with free side events and an evening of competitive brackets!</p>
                    <p>Hang out, meet the MN FGC, and test your might!</p>
                </div>
                <br></br>
                <h5>Click one of the cards below to view game rules and details.</h5>
                <h5>Times are all tentative.</h5>
                <div className="game-cards-container">
                    <GameCard cardImage={makeStaticContentLink('sfvlogo.png')} game='sfv' bracketInfo="Bracket @ 1:00PM"></GameCard>
                    <GameCard cardImage={makeStaticContentLink('mbtl-logo.png')} game='mbtl' bracketInfo="Bracket @ 3:00PM"></GameCard>
                    <GameCard cardImage={makeStaticContentLink('ggst-logo.png')} game='ggst' bracketInfo="Bracket @ 5:00PM"></GameCard>
                    <GameCard cardImage={makeStaticContentLink('dbfz-logo.png')} game='dbfz' bracketInfo="Bracket @ 7:00PM"></GameCard>
                    <GameCard cardImage={makeStaticContentLink('gbvs-logo.png')} game='gbvs' bracketInfo="Bracket @ 4:00PM"></GameCard>
                    <GameCard cardImage={makeStaticContentLink('bbcf-logo.png')} game='bbcf' bracketInfo="Bracket @ 2:00PM"></GameCard>
                    <GameCard cardImage={makeStaticContentLink('tekken_logo.png')} game='t7' bracketInfo="Bracket @ 2:00PM"></GameCard>

                </div>
                <br></br>
                {/* <div onClick={() => toggleModal(true)} className="covid-policy">
                    <span>Click here for our COVID mitigation policy.</span>
                </div> */}
                <br></br>

                <p>We reserve the right to change details of the tournament including but not limited to bracket times and formats as registrations come in.</p>
                <p>Any changes will be announced on our Discord channel well in advance of the tournament.</p>
                </div>
            </div>
            {/* <Modal show={showModal} handleClose={toggleModal}>
                <h5>COVID Mitigation Policy</h5>
                <p>RunTheMix strongly recommends all attendees seek COVID vaccination and boosters more than 2 weeks prior to the event, but we are unable to require vaccination.</p>
                <p>Please follow our Discord for updates or changes to our COVID mitigation policy in the leadup to the event.</p>
            </Modal> */}
            <Modal show={showSecondModal} handleClose={toggleSecondModal}>
                <h5>The signup isn't live yet.</h5>
                <p>This will link to the Smash.gg as soon as it's ready.</p>
            </Modal>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className="mixfest-footer">
                <img className="footer-logo" src={makeStaticContentLink('RunTheMixLogo.png')}></img>
            </div>

    </div>
  );
};

export default Monthly;

//style={{backgroundImage: `${process.env.PUBLIC_URL}/gif-png-snow-2.gif`}}