import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Landing from './views/Landing';
import VODS from './views/vods';
import Events from './views/events';
import FAQ from './views/FAQ';
import Mixfest from './views/mixfest';
import Monthly from './views/monthly';
import Pete from './views/pete';

// Home route for the '/' route is commented out until authentication is implemented.

function App() {
  return (
    <div>
      <Router>
      <NavBar />
          <Switch>
            <Route exact path='/' component={Landing}/>
            <Route exact path='/faq' component={FAQ}/>
            <Route exact path='/events' component={Events} />
            <Route exact path='/vods' component={VODS} />
            <Route exact path='/Pete' component={Pete} />
            {/* <Route exact path='/mixfest' component={Mixfest} /> */}
            <Route exact path='/monthly' component={Monthly} />
          </Switch>
        </Router>

        {/* <Footer /> */}
    </div>
  );
}

export default App;
