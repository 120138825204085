import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.scss';

const Landing = () => {
  return (
    <div className="landing-container">

      {/* <p className="landing-header">
        Midwest Mixfest is January 7th!
      </p> */}
        {/* <a href="https://start.gg/mixfest">
        <img className="monthly-logo" src={process.env.PUBLIC_URL + '/MidwestMixfestLogoReborn.png'}></img>
<h2>Click here for Midwest Mixfest details!</h2></a> */}

      <br></br>


      <p className="landing-text">
        Join our
        <span className="bold"> Discord Server</span>
        
        
      </p>
      <div>
      <a href="https://discord.gg/uzUa5zgtFg">

        <button className="landing-button" variant="light">
          <i className='fab fa-discord fa-2x' style={{color:"black"}}></i>
        </button>
        </a>

      </div>

      <p className="landing-text">
        Run The Mix hosts a weekly fighting game event featuring Street Fighter 6, Guilty Gear Strive, Melty Blood Type Lumina, and Tekken 7, but hosting any fighting game that comes in the door.
        <br></br>
        <br></br>
        We're a community of fighting game players grinding, improving, competing, and enjoying the games we love the way they were meant to be played:
        <br></br>
        <span className="landing-text__bigger">Within striking distance.</span>
      </p>
      <p className="landing-text">
        Come hang out on Wednesdays at GameZenter in Roseville to play casuals for a wide variety of fighting games and brackets for whatever we're running that week.  $5 door/casuals and $5 per bracket.  Casuals start at 5:30 and bracket signups end at 6:45.  Check our Twitter and Discord for udpates.  Hope to see you there!
      </p>

              <iframe
              frameborder="0"
              className="landing-page-map"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBXkC7_gahMFeZGE9GcVj2ZEefP08hu1Yo&q=GameZenter,Roseville+Minnesota" allowfullscreen>
            </iframe>

    </div>
  );
};

export default Landing;
