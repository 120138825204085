import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Modal from '../components/modal/modal';
import './_faq.scss';
import '../components/modal/modal.scss';

const FAQ = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="page-content-container">
      <h1 className="page-header">Frequently Asked Questions</h1>
      
      <div className="question-block">
          <h2 className="question-block__header">What games are played at RTM Weeklies?</h2>
          <p className="question-block__body">Street Fighter 6, Guilty Gear Strive, Melty Blood Type Lumina, and Tekken 7 are our mainstays.  Many other games get one-off brackets on certain weeks or pop up in our bring-your-own-console area on a weekly basis (SFV, GBVS, BBCF, KoF XV).</p>
      </div>
      <div className="question-block">
          <h2 className="question-block__header">What platforms do you play games on?</h2>
          <p className="question-block__body">All games are played on PS4 except for SF6 which is on PS5.</p>
      </div>
      <div className="question-block">
        <h2 className="question-block__header">Is outside food and drink allowed?</h2>
        <p className="question-block__body">No.  GameZenter has food and drink available, including burgers, paninis, burritos, and beer on tap  <a className="question-block__body__click-span" href="https://wisdomstudios.gg/the-high-ground#menu">Click HERE to see their menu.</a></p>
      </div>
      <Modal show={showModal} handleClose={setShowModal}>
        <h5>Delivery</h5>
        <p>Parkway Pizza - Free Delivery</p>
        <p>Lien Son - Vietnamese and Chinese</p>
        <br></br>
        <h5>Door Dash</h5>
        <p>Brasa Northeast</p>
        <p>Rusty Taco</p>
      </Modal>
      <div className="question-block">
        <h2 className="question-block__header">What's the parking situation like?</h2>
        <p className="question-block__body">GameZenter has a free parking lot.</p>
      </div>
      <div className="question-block">
        <h2 className="question-block__header">Do I need to bring my own controller or console?</h2>
        <p className="question-block__body">We have consoles on site with games to play.  We recommend bringing your own controller, but we have PS4 controllers players can borrow.</p>
      </div>
      {/* <div className="question-block">
        <h2 className="question-block__header">Do you have a COVID safety policy?</h2>
        <p className="question-block__body">There is hand sanitizer on site.  If you have <span className="question-block__body__italics-bold">ANY SYMPTOMS OF ANY POTENTIALLY CONTAGIOUS ILLNESS OF ANY KIND</span>, please just stay home.</p>
      </div> */}
      <div className="question-block">
        <h2 className="question-block__header">Does your weekly happen EVERY Wednesday?</h2>
        <p className="question-block__body">Run The Mix Weeklies happen <span className="question-block__body__italics-bold">ALMOST</span> every Wednesday.  We cancel following some major tournaments and for some holidays.</p>
      </div>
      
    </div>
  );
};

export default FAQ;
