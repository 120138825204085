import React from 'react';
import './pete.scss';

const Pete = () => {
    return (
        <div className="pete-container">
            <h1>Go play Melty Blood</h1>
            <h1>It's a good game</h1>
        </div>

    )
}

export default Pete;