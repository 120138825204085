import React, { useState } from 'react';
import Modal from '../components/modal/modal';
import './game-card.scss';

export default function GameCard ({cardImage, game,  bracketInfo}) {
    const [showModal, setShowModal] = useState('');
    const handleButtonClick = (gameString) => {
        setShowModal(gameString);
    }
    return (
        <div className="game-card-wrapper">
            <img onClick={() => handleButtonClick(game)}className="game-card-logo" src={cardImage}></img>
            {/* <p>{bracketInfo}</p> */}
            <Modal show={showModal === 'sfv'} handleClose={() => setShowModal('')}>
                <h5>SFV Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 1:00 PM</p>
                <p>Registration ends at 12:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>32 registrant cap</p>
                <p>Top 3 payout</p>
                <p>Matches are 2 of 3 except semis and finals which are 3 of 5</p>
                <a href="https://capcomprotour.com/rules/">CPT Rules</a>
                <p>Refer to CPT rules for banned stages and costumes</p>
                <p>CPT rules exception: Blanka Chan is an allowed costume</p>
            </Modal>
            <Modal show={showModal === 'mbtl'} handleClose={() => setShowModal('')}>
                <h5>Melty Blood Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 3:00 PM</p>
                <p>Registration ends at 2:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>32 registrant cap</p>
                <p>Top 3 payout</p>
                <p>All matches are 3 of 5</p>
                <p>Double elim</p>
            </Modal>
            <Modal show={showModal === 'ggst'} handleClose={() => setShowModal('')}>
                <h5>Strive Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 5:00 PM</p>
                <p>Registration ends at 4:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>32 registrant cap</p>
                <p>Top 3 payout</p>
                <p>Matches are 2 of 3 except semis and finals which are 3 of 5</p>
                <p>Double elim</p>
            </Modal>
            <Modal show={showModal === 'dbfz'} handleClose={() => setShowModal('')}>
                <h5>DBFZ Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 7:00 PM</p>
                <p>Registration ends at 6:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>32 registrant cap</p>
                <p>Top 3 payout</p>
                <p>Matches are 2 of 3 except semis and finals which are 3 of 5</p>
                <p>Winner must keep same team and assists</p>
                <p>Loser may change team, assists, and order</p>
            </Modal>
            <Modal show={showModal === 'gbvs'} handleClose={() => setShowModal('')}>
                <h5>GBVS Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 4:00 PM</p>
                <p>Registration ends at 3:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>More details TBD</p>
            </Modal>
            <Modal show={showModal === 'bbcf'} handleClose={() => setShowModal('')}>
                <h5>BBCF Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 2:00 PM</p>
                <p>Registration ends at 1:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>More details TBD</p>
            </Modal>
            <Modal show={showModal === 't7'} handleClose={() => setShowModal('')}>
                <h5>Tekken 7 Rules</h5>
                <p>Platform: PS4</p>
                {/* <p>Bracket starts at 2:00 PM</p>
                <p>Registration ends at 1:00 PM</p> */}
                <p>Bracket times TBD</p>
                <p>More details TBD</p>
            </Modal>
        </div>
    )
}