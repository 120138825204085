import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Modal from '../components/modal/modal';
import '../components/modal/modal.scss';
import './events.scss';

const Events = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="body-container">
      <h1>Events</h1>
      <p className="landing-header">
        Registration for Midwest Mixfest Fall '23 is live now!
        <br></br>
        <a href='start.gg/mixfest'>Click here to sign up for Mixfest!</a>
        <br></br>
        {/* <Link to="/monthly">Click HERE to learn more!</Link> */}
      </p>
      {/* <a href='start.gg/runthemix'>Click here to sign up for our next weekly!</a> */}
      <br></br>
      <p>Pete really needs to find a way to keep this page updated more easily.</p>
      <p>We're live with weeklies every Wednesday night at GameZenter in Roseville.</p>
      <p>5:30 doors.  6:45 brackets.</p>
      <p>Be sure to check the discord for any updates.</p>
      <p>Mention to Pete that you read this and he might finally make a real events page.</p>
      <br></br>


    </div>
  );
};

export default Events;
