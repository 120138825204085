import React, {useState} from 'react';
import GameCard from '../components/game-card';
import Modal from '../components/modal/modal';
import '../components/modal/modal.scss';
import './events.scss';

const Mixfest = () => {
    const makeStaticContentLink = (path) => `${process.env.PUBLIC_URL}/${path}`;
  const [showModal, toggleModal] = useState(false); 
  const [showSecondModal, toggleSecondModal] = useState(false);
  return (
    <div className="mixfest-container">
            <img className="city-layer" src={makeStaticContentLink('minneapolis-skyline-png.png')}></img>
            <img className="snow-gif" src={makeStaticContentLink('gif-png-snow-2.gif')}></img>
            <div className="mixfest-content">
                <img className="big-ol-logo" src={process.env.PUBLIC_URL + '/MixfestLogo.png'}></img>
                <div className="logo-sub-description">
                    <p>Jan 8, 2022 @ BLACK ICE ESPORTS in Minneapolis</p>
                    <p className="tournament-time">10:00 AM - 10:00 PM | $10 venue + $10 pot per bracket</p>
                </div>

                <div className="register-button-wrapper">
                <a className="register-now" href="https://smash.gg/tournament/mn-mixfest">
                    <p className="register-text">REGISTER HERE NOW!</p>
                </a>
                <br></br>
                <div className="mixfest-info-block">
                    <p>Come sharpen your skills in the leadup to Frosty Faustings in 4 fighting game brackets.</p>
                    <p>RunTheMix and Black Ice eSports are putting up a combined $650 in pot bonuses across 4 games.</p>
                    <p>Plus - we're running casuals for all 4 games all day long!</p>
                    <p>Come hang out, meet the MN FGC, and test your might!</p>
                </div>
                <br></br>

                <h5>Click one of the cards below to view game rules and details.</h5>
                <div className="game-cards-container">
                    <GameCard cardImage={makeStaticContentLink('ggst-logo.png')} game='ggst'></GameCard>
                    <GameCard cardImage={makeStaticContentLink('mbtl-logo.png')} game='mbtl'></GameCard>
                    <GameCard cardImage={makeStaticContentLink('dbfz-logo.png')} game='dbfz'></GameCard>
                    <GameCard cardImage={makeStaticContentLink('umvc3-logo.png')} game='umvc3'></GameCard>
                </div>
                <br></br>
                <div onClick={() => toggleModal(true)} className="covid-policy">
                    <span>Click here for our COVID mitigation policy.</span>
                </div>
                <br></br>

                <p>We reserve the right to change details of the tournament including but not limited to bracket times and formats as registrations come in.</p>
                <p>Any changes will be announced on our Discord channel well in advance of the tournament.</p>
                </div>
            </div>
            <Modal show={showModal} handleClose={toggleModal}>
                <h5>COVID Mitigation Policy</h5>
                <p>At this time, we are requiring all attendees to wear masks when not eating or drinking.</p>
                <p>RunTheMix strongly recommends all attendees seek COVID vaccination and boosters more than 2 weeks prior to the event, but we are unable to require vaccination.</p>
                <p>Please follow our Discord for updates or changes to our COVID mitigation policy in the leadup to the event.</p>
            </Modal>
            <Modal show={showSecondModal} handleClose={toggleSecondModal}>
                <h5>The signup isn't live yet.</h5>
                <p>This will link to the Smash.gg as soon as it's ready.</p>
            </Modal>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className="mixfest-footer">
                <img className="footer-logo" src={makeStaticContentLink('RunTheMixLogo.png')}></img>
            </div>

    </div>
  );
};

export default Mixfest;

//style={{backgroundImage: `${process.env.PUBLIC_URL}/gif-png-snow-2.gif`}}