import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const NavBar = () => {
  return (
    <div>
      <div className="navigation-bar" variant="dark" expand="lg">
        <div className="navbar-wrapper">
          <div className="nav-brand">
            <div className="nav-margin-container">
              <div href="/" className="nav-brand navbar-brand">
                <span className="logo-uppercase">R</span>
                <span className="logo-normal">UN </span>
                <span className="logo-uppercase">T</span>
                <span className="logo-normal">HE </span>
                <span className="logo-uppercase">M</span>
                <span className="logo-normal">IX</span>
              </div>
            </div>
          </div>
          <div className="nav-buttons-container">
            <Link to="/" className="nav-button">HOME</Link>
            <Link to="/events" className="nav-button">EVENTS</Link>
            <Link to="/faq" className="nav-button">FAQ</Link>
            <Link to="/vods" className="nav-button">VODS</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
